
import React, { useState } from "react";
import "./style.css";

export const Frame = () => {

  const [inputValue, setInputValue] = useState('');
  const [videoUrl, setVideoUrl] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    const data = { user_input: inputValue };

    fetch('https://av.pranathiss.com/api/generate_video/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success && data.video_url) {
        setVideoUrl(data.video_url);
      } else {
        console.error('Video URL not found in the response');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };



  return (
    <div className="frame">
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="rectangle" />
          <div className="div" />
          <div className="group">
            <div className="text-wrapper">Chat with</div>
            <div className="text-wrapper-2">Siri (chat bot name)</div>
            <img className="fi" alt="Fi" src="/img/fi-2961937.svg" />
            <div className="group-2" />
            <img className="line" alt="Line" src="/img/line-26.svg" />
          </div>

          <div className="chat-and-video-container">
            {/* Left side: Chat messages */}
            <div className="chat-section">
              <div className="chat">
                <div className="operator">
                  <div className="message-wrapper">
                    <div className="message">
                      <div className="div-wrapper">
                        <p className="p">👋&nbsp;&nbsp;Hello and Welcome to our site!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="message-wrapper">
                    <div className="message-2">
                      <div className="div-wrapper">
                        <p className="p">My name is Siri, How can I assist you today?</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="operator-2">
                  <div className="message-wrapper">
                    <div className="message">
                      <div className="div-wrapper">
                        <p className="p">Sure! Please choose an option</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="operator-3">
                  <div className="message-wrapper">
                    <div className="message">
                      <div className="div-wrapper">
                        <p className="p">To view your leave balance</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="operator-4">
                  <div className="message-wrapper">
                    <div className="message-3">
                      <div className="click-on-the-leave-wrapper">
                        <p className="click-on-the-leave">Click on the "Leave" section in your dashboard</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper-2">
                  <p className="text-wrapper-4">I need to check my leave balance</p>
                </div>
                <div className="fi-wrapper">
                  <img className="fi-6" alt="Fi" src="/img/fi-456212.svg" />
                </div>
              </div>
            </div>

            {/* Right side: Video player */}
            <div className="video-section">
              {videoUrl && (
                <div>
                  <h3>Your Video:</h3>
                  <video width="100%" controls autoPlay>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
          </div>
         

          <div className="overlap-group-wrapper">      
            <div className="overlap-group">
              <div className="type-text">
                <div className="overlap-group-2">
                  <div className="group-4">
                    <img className="line-3" alt="Line" src="/img/line-25.svg" />
                    <div>
                      <div className="text-wrapper-3">Type Your Message...</div>
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder="Enter your message"
                      />
                    </div>
                    <img className="fi-2" alt="Fi" src="/img/fi-561106.svg" />
                    <img className="fi-3" alt="Fi" src="/img/fi-1933691.svg" />
                  </div>
                  <img className="fi-4" alt="Fi" src="/img/fi-5264505.svg" onClick={handleSubmit} />
                </div>
              </div>
              <img className="fi-5" alt="Fi" src="/img/fi-107737.svg" />
             
            </div>


           



          </div>
        </div>
      </div>

    
    </div>
  );
};
