







// with new api from akhil  converting code from audio to text from aman api


import React, { useState, useRef, useEffect } from 'react';
import Picker from 'emoji-picker-react';
// import './newFrameResponsive.css';
import './frameNew.css';


// import chatBoxImage from '../../../static/img/chatBoxImage.jpg'
import chatBoxImage from '../../../static/img/chatBoxImageCrop.jpg'
import arrowDown from '../../../static/img/arrowDownConvert.png'
import { useFetcher } from 'react-router-dom';
// import staticVideo from '../../../static/img/natasha_static3.mp4'

// import chatBoxImage from '../Images/chatBoxImage.jpg'

const MessageInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const [audioToText, setAudioToText] = useState('');

  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [showChatBot, setShowChatBot] = useState(false);

  const [recordMessage, setRecordMessage] = useState(false);

  const [isApiCalled, setIsApiCalled] = useState(false);

  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const chatHeadRef = useRef(null);
  const messagesEndRef = useRef(null); // Ref for the autoscroll
  const inputRef = useRef(null);

  const [showInitialText, setShowInitialText] = useState(true);
  const [recentBotIndex, setRecentBotIndex] = useState(null);

  const [showWelcomeMsg, setShowWelcomeMsg] = useState(false);

  const [showWelcomeVideo, setShowWelcomeVideo] = useState(true);

  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('English'); // Set English as default

  // Array of languages
  const languages = [
    'Hindi', 'Tamil', 'Telugu', 'Kannada', 'Marathi', 'Malayalam',
    'Bengali', 'Gujarati', 'Rajasthani', 'Assamese', 'Punjabi', 'English'
  ];

  const handleLanguageToggle = () => {
    setShowLanguages(!showLanguages);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language); // Update the selected language text
    setShowLanguages(false); // Hide the dropdown after selection
  };


  // Handle when the welcome video ends
  const handleWelcomeVideoEnd = () => {
    setShowWelcomeVideo(false); // After welcome video ends, switch to the static video
  };

  useEffect(() => {
    // Set showWelcomeMsg to true after 1300ms
    const showTimeout = setTimeout(() => {
      setShowWelcomeMsg(true);
    }, 1);

    // Set showWelcomeMsg to false after a delay (e.g., 3000ms) after showing the message
    const hideTimeout = setTimeout(() => {
      setShowWelcomeMsg(false);
    }, 3000);

    // Clean up the timeouts when the component unmounts
    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, []);

  useEffect(() => {
    // Find the last bot (receiver) message
    const latestBotIndex = messages
      .map((item, index) => (item.type === 'bot' ? index : null))
      .filter(index => index !== null)
      .pop(); // Get the last bot message index

    if (latestBotIndex !== null) {
      setRecentBotIndex(latestBotIndex);

      // Set the timer for the most recent bot message
      const timer = setTimeout(() => {
        setShowInitialText(false);
      }, 1300); // Adjust the delay as needed

      setShowInitialText(true); // Reset the state when a new message comes in

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [messages]); // Trigger effect when messages change

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      // Allow new line on Shift + Enter
      return;
    }
    if (e.key === 'Enter') {
      // Prevent default behavior if just Enter is pressed
      e.preventDefault();
      handleSendMessage();
    }
  };


  // new code with akhil response start 

  const handleSendMessage = async () => {
    if (inputValue.trim() || audioToText) {
      setLoading(true);
      setIsApiCalled(true); // Mark API as called
  
      try {
        const payload = {
          user_input: audioToText ? audioToText : inputValue.trim() || '',
        };
  
        // Scroll down to show the dot animation immediately when user sends the message
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
  
        const response = await fetch('https://avengine.pranathiss.com/api/company/varundigital/home/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        const data = await response.json();
  
        // Clear inputs
        setAudioToText('');
        setInputValue('');
        setAudioBlob(null);
        setTimer(0);
  
        // Simulate typing indicator (dot class) before showing the message
        setTimeout(() => {
          // Handle the API response and show the message
          handleResponse(data);
  
          // After showing the message, scroll to the bottom again after 1500 ms (1.5 seconds)
          setTimeout(() => {
            if (messagesEndRef.current) {
              messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }, 1500); // Scroll after 2.5 seconds
  
        }, 1000); // Adjust this delay based on how long you want to show the dot typing animation
  
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };


  const handleResponse = (data) => {
    // Add the user message
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        message: inputValue.trim() || '',
        type: 'user',
        // audioUrl: audioBlob ? URL.createObjectURL(audioBlob) : null,
        audioUrl: audioToText,
        isActive: true
      },
    ]);

    // Check if there's a response text
    if (data.responses && data.responses.length > 0) {
      const botMessage = data.responses.join(' ');
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: botMessage,
          type: 'bot',
        },
      ]);
    } else {
      // Fallback message if there are no responses
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          message: "Could you please repeat?",
          type: 'bot',
        },
      ]);
    }


    // Handle video response
    if (data.video_path) {
      // const fullVideoUrl = `https://avengine.pranathiss.com${data.video_path}`;
      const fullVideoUrl = data.video_path;
      setVideoUrl(fullVideoUrl); // Set the video URL to play the API response video
    } else {
      setVideoUrl(''); // Reset video URL to fallback to static video
    }
  };


  useEffect(() => {
    if (videoUrl) {
      setVideoLoading(true);
      const video = videoRef.current;
      console.log('videovideo', video);
      if (video) {
        video.onloadeddata = () => {
          setVideoLoading(false);
          video.onended = () => {
            // When the API video ends, fallback to static video
            setVideoUrl('');
          };
        };
      }

    }
  }, [videoUrl]);

  // new code with akhil response end



  useEffect(() => {
    if (!loading && !recording) {
      inputRef.current?.focus();
    }
    if (showWelcomeVideo) {
      inputRef.current?.focus();
    }
  }, [loading, recording, inputValue, messages]);





  const startRecording = async () => {
    setRecording(true);
    setRecordMessage(false)
    setTimer(0);
    audioChunksRef.current = [];
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };
    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
      setAudioBlob(blob);
    };
    mediaRecorderRef.current.start();
    timerRef.current = setInterval(() => setTimer((prev) => prev + 1), 1000);
  };

  const stopRecording = () => {

    setRecordMessage(true)
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    clearInterval(timerRef.current);



  };

  useEffect(() => {
    if (audioBlob) {
      sendAudioToApi(audioBlob);
    }
  }, [audioBlob]);


  useEffect(() => {
    const timer = setTimeout(() => {

      setRecordMessage(false);  // Set the state to false after 3 seconds
    }, 3000);

    // Cleanup the timer when the component is unmounted or before re-running the effect
    return () => clearTimeout(timer);
  }, [recordMessage]);  // Empty dependency array ensures the effect runs only once

  const sendAudioToApi = async (blob) => {
    try {
      // Convert blob to Base64
      const base64Audio = await convertBlobToBase64(blob);

      // Extract the file extension from the blob type
      const fileExtension = blob.type.split('/')[1]; // Extract 'webm' from 'audio/webm'

      // Prepare the FormData
      const formData = new FormData();
      formData.append('audio_file', base64Audio.split(',')[1]); // Base64 data without the prefix
      formData.append('file_extension', fileExtension);

      // Send the POST request
      const response = await fetch('https://speech.pranathiss.com/api/vad-base64-speech-to-text/', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      setAudioToText(data.response)

      if (data.response) {
        setTimeout(() => {
          setRecordMessage(false)
        }, 880);
      }


    } catch (error) {
      console.error('Error sending audio:', error);
    }
  };


  // Utility function to convert blob to Base64
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };





  useEffect(() => {
    if (videoRef.current) {
      setVideoLoading(true);
      videoRef.current.load();
      videoRef.current.play().finally(() => {
        setVideoLoading(false);
      });
    }
  }, [videoUrl]);

  const onEmojiClick = (emojiObject) => {
    setInputValue((prevInput) => prevInput + emojiObject.emoji);
  };

  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = isSoundOn;
      setIsSoundOn(!isSoundOn);
    }
  };

  const handleChatBotOpen = () => {
    setShowChatBot(!showChatBot);
  };

  useEffect(() => {
    setShowChatBot(true);
  }, []);


  useEffect(() => {
    handleSendMessage()
  }, [audioToText])
  const handleVideoEnd = () => {
    setVideoUrl('/img/natasha_static3.mp4')
  }


  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500); // Small delay to ensure smooth rendering of new API message
    }
  }, [messages]); // Trigger this effect whenever the `messages` array changes







  return (
    // <div className={!showChatBot ? "newvideochartvalue" : "videochartvalue"}>

    <>
      {
        !showChatBot ?
          <div className="videochartvalue ">
            <div className='container'>

              <div className='chatHead'>
                <div className='headIcontext'>
                  <div className='group-2'></div>
                  <div className='iconHeadChat'>
                    <img src="/img/chatAvatarIcon3.png" alt="Avatar" />
                    <div className='headTextClass'>
                      <div className="text-wrapper miniHead">Chat with</div>
                      <div className="text-wrapper-2">AI Assistant</div>
                    </div>
                  </div>
                </div>
                <div className='headMinClose'>
                  <div className='languagesDiv' onClick={handleLanguageToggle}>
                    {selectedLanguage}
                    {/* Dropdown arrow */}
                    <span className={`arrow ${showLanguages ? 'open' : ''}`}>
                      {/* ▼ */}
                      <img src={arrowDown} alt="" className='downArrowClass' />
                    </span>
                    {showLanguages && (
                      <ul className='languageDropdown'>
                        {languages
                          .filter(language => language !== selectedLanguage) // Filter out selected language
                          .map((language, index) => (
                            <li key={index} onClick={() => handleLanguageSelect(language)}>
                              {language}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
                  <img src="/img/chatMoreIcon.png" alt="More" />
                  <img src="/img/minimizeChatWhite.png" alt="Line" onClick={handleChatBotOpen} />

                </div>
              </div>



              <div id="output-container">
                <div className="messages-container">
                  {
                    showWelcomeMsg ?


                      <div className='message-item receiver'>
                        <div className='senderIconText'>

                          <div className='message'>
                            <div className="chat-bubble">
                              <div className="typing">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                              </div>
                            </div>
                          </div>
                          <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                        </div>
                      </div>

                      :
                      <div className='receiverIconText'>
                        <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />
                        <div className="success-status">
                          {/* Hello and Welcome to our site ! */}
                          Hello, Welcome to Varun Digital !
                        </div>
                      </div>
                  }



{messages.map((item, index) => (
  <div key={index} className={`message-item ${item.type === 'user' ? 'sender' : 'receiver'}`}>
    <div className='senderIconText'>
      <div className="message">
        {
          item.audioUrl ?
            recordMessage && index === recentBotIndex ? (
              <div className="chat-bubble-audio">
                <div className="typing">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            ) : (
              <p>{item.audioUrl}</p>
            )
          : (
            item.type === 'bot' && index === recentBotIndex && showInitialText ? (
              <div className="chat-bubble">
                <div className="typing">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            ) : (
              <p>{item.message}</p>
            )
          )
        }
      </div>
      {item.type === 'user' && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
      {item.type === 'bot' && <img src="/img/chatAvatarIcon3.png" alt="Receiver Avatar" />}
    </div>
    <div ref={messagesEndRef} /> {/* Auto-scroll target */}
  </div>
))}

                  {recordMessage ?
                    <div className="senderIconText">
                      <div className="message">
                        <div className="typing">
                          <div className="dot"></div>
                          <div className="dot"></div>
                          <div className="dot"></div>
                        </div>
                      </div>
                      <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />

                    </div>
                    : ""}

                  <div ref={messagesEndRef} /> {/* Auto-scroll target */}
                </div>




                {/* new code with akhil response start */}

                <div className="hello-container">
                  {showWelcomeVideo ? (
                    // Play the welcome video only on the first load
                    <video width="100%" autoPlay onEnded={handleWelcomeVideoEnd}>
                      <source src="/img/natasha_staticwelcome.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>


                  ) : (
                    <div>
                      {!isApiCalled || !videoUrl ? (
                        // Play the static video when there's no API video to play
                        <video width="100%" autoPlay loop muted>
                          <source src="/img/natasha_static3.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <div>
                          {videoLoading && (
                            <div className="staticVideoClass">
                              <video width="100%" autoPlay loop muted>
                                <source src="/img/natasha_static3.mp4" type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                          <div>
                            {recentBotIndex && showInitialText ? (
                              <div className='loaderVideoOutside'>
                                <div className="loaderVideo"></div>
                              </div>
                            ) : (
                              // Play API response video when available
                              <video ref={videoRef} width="100%" autoPlay key={videoUrl} onEnded={handleVideoEnd}>
                                <source src={videoUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>


                {/* new code with akhil response end */}



              </div>
              {/* <div className='helpfulText'>
                  <p>Was this helpful ?</p>
                  <img src="/img/thumbsUpIcon.png" alt="Thumbs Up" className='hoverZoom' />
                  <img src="/img/thumbsDownIcon.png" alt="Thumbs Down" className='thumbsDownClass hoverZoom' />
                </div> */}


              <div className='outputHelpfulClass'>

                <div className='helpfulText'>
                  <p>Was this helpful ?</p>
                  <img src="/img/thumbsUpIcon.png" alt="Thumbs Up" className='hoverZoom' />
                  <img src="/img/thumbsDownIcon.png" alt="Thumbs Down" className='thumbsDownClass hoverZoom' />
                </div>

                <div className="input-container">
                  <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter your message"
                    disabled={loading || recording}
                    ref={inputRef} // Add the ref here
                  />




                  {/* <textarea name="textarea" 
  onKeyDown={handleKeyDown}
   type="text"
   value={inputValue}
   onChange={(e) => setInputValue(e.target.value)}
   onKeyPress={handleKeyPress}
   placeholder="Enter your message"
   disabled={loading || recording}
></textarea> */}


                  {recording ? (
                    <div className="recording-indicator">
                      <div onClick={stopRecording} disabled={loading} className='iconButton'>
                        <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                      </div>
                    </div>
                  ) : (
                    <div className='iconButton' onClick={startRecording} disabled={loading}>
                      <img src="/img/chatMicIcon.png" alt="Mic" />
                    </div>
                  )}
                  <div className='iconButton' onClick={toggleSound}>
                    {isSoundOn ? (
                      <img src="/img/chatSoundIcon.png" alt="Speaker" />
                    ) : (
                      <img src="/img/chatSpeakerIcon.png" alt="Speaker" />
                    )}
                  </div>
                  <div className='iconButton' onClick={handleSendMessage} disabled={loading || recording}>
                    <img src="/img/chatSendIcon.png" alt="Send" />
                  </div>
                </div>
              </div>
            </div>

          </div>
          :
          <div className='chatBoxImageClass' onClick={handleChatBotOpen}>
            <img src={chatBoxImage} alt="" />
          </div>
      }

    </>

  );
};

export default MessageInput;


